<template>
  <div>
    <van-nav-bar
      title="三保障和饮水安全"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="basicBox" >
      <!-- <div class="codeNum">
        <div>年收入（元）</div>
        <div>{{guaranteesForm.familyNetIncome}}</div>
      </div> -->
      <div class="codeNum">
        <div>住房出现安全问题</div>
        <div>{{whetherFn(guaranteesForm.isHousingSecurity)}}</div>
      </div>
      <div class="codeNum">
        <div>义务教育阶段适龄儿童少年失学辍学</div>
        <div>{{whetherFn(guaranteesForm.isDropOutSchool)}}</div>
      </div>
      <div class="codeNum">
        <div>有家庭成员未参加城乡居民基本医疗保险</div>
        <div>{{whetherFn(guaranteesForm.isMedicareInsurance)}}</div>
      </div>
      <div class="codeNum">
        <div>饮水出现安全问题</div>
        <div>{{whetherFn(guaranteesForm.isWaterSafety)}}</div>
      </div>
    </div>
    <div class="opinion" @click="goOpinion">意见及诉求</div>
  </div>
</template>

<script>
import { findtroubleshootFamily } from "@/api/atp.js";
export default {
  data() {
    return {
      searchObj: {
        idCard: JSON.parse(sessionStorage.getItem('postForm')).idCard,
      },
      guaranteesForm:{}
    };
  },
  mounted(){
    this.getAll()
  },
  methods: {
    // 是否方法
    whetherFn (e) {
      if (e === true) {
        return '是'
      } else if (e === false) {
        return '否'
      } else {
        return ''
      }
    },
    getAll() {
      findtroubleshootFamily(this.searchObj)
        .then((res) => {
          if (res.status === 200) {
            this.guaranteesForm=res.data
          } else {
            this.$toast("查询数据失败");
          }
        })
        .catch(() => {
          this.$toast("获取基本信息失败");
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goOpinion() {
      this.$router.push("/opinion");
    },
  },
};
</script>

<style scoped>
.opinion {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: #f0ffff;
  color: #1e90ff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
>>> .van-nav-bar__content {
  height: 100%;
}
>>> .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
>>> .van-icon-arrow-left,
>>> .van-nav-bar__text {
  font-size: 0.14rem;
}
.basicBox {
  margin-bottom: 0.5rem;
}
.basicBox > div {
  border-bottom: 1px solid #eee;
  width: 94%;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.basicBox > div > div {
  line-height: 0.5rem;
}
</style>